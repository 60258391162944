<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onModalChange"
  >
    <template #activator="{ on: onDialog }">
      <v-tooltip
        bottom
        max-width="600"
      >
        <template #activator="{ on: onTooltip }">
          <v-icon
            data-cy="payment-cancel"
            v-on="{ ...onDialog, ...onTooltip }"
          >
            {{ deleteIcon }}
          </v-icon>
        </template>
        <span v-t="'payment.disablingRecurringPaymentsWarning'" />
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <span
          v-t="'payment.cancelRecurringPayments'"
          class="headline"
          data-cy="payment-cancel-modal"
        />
        <v-spacer />
        <v-icon
          data-cy="payment-cancel-close-button"
          @click="closeModal()"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>

      <v-divider />
      <v-card-text>
        <v-container class="cancel-payments-modal-window-container">
          <h3
            v-t="'payment.pleaseTellUsTheReasonForYourCancellation'"
            class="text-center"
          />
          <v-row
            justify="center"
            data-cy="cancel-radio"
          >
            <v-radio-group v-model="selectedReasonModel">
              <v-radio
                v-for="reason in cancellationReasons"
                :key="reason"
                :label="$t(`payment.${camelize(reason)}`)"
                :value="reason"
              />
            </v-radio-group>
          </v-row>
          <v-row justify="center">
            <v-textarea
              v-model="feedback"
              :label="$t('payment.yourFeedbackIsGreatlyAppreciated')"
              :messages="selectedReason === 'Other' ? messages : []"
              :error="Boolean(selectedReason === 'Other' && messages.length)"
              data-cy="payment-cancel-feedback"
            />
          </v-row>
          <v-row justify="center">
            <v-btn
              rounded
              depressed
              color="primary"
              data-cy="payment-cancel-modal-submit"
              @click="submitCancellation"
            >
              {{ $t('payment.proceedToCancellation') }}
            </v-btn>
          </v-row>
        </v-container>
        <v-divider class="my-5" />
        <v-container class="cancel-payments-modal-window-container">
          <h3
            v-t="'payment.wouldYouLikeToDiscussAnythingDontHesitateToContactMe'"
            class="text-center"
          />
          <div class="ma-auto mt-3 cancel-payments-manager-contact">
            <div style="text-decoration: underline;">
              {{ manager.name }}
            </div>
            <div>
              <span v-t="'payment.email'" />
              <span class="font-weight-bold">
                {{ manager.email }}
              </span>
            </div>
            <div>
              <span v-t="'payment.phone'" />
              <span class="font-weight-bold">
                {{ manager.phone }}
              </span>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { CLOSE_ICON, DELETE_ICON } from '../../../../constants/app'
export default {
  props: {
    paymentId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      feedback: '',
      deleteIcon: DELETE_ICON,
      closeIcon: CLOSE_ICON,
      cancellationReasons: [
        'We stopped using the application',
        'We want to manage the payments manually',
        'We are switching to a different payment method',
        'Other'
      ],
      defaultReason: 'We want to manage the payments manually',
      selectedReason: 'We want to manage the payments manually',
      messages: []
    }
  },
  computed: {
    ...mapState('client', ['clients']),
    selectedReasonModel: {
      get () {
        return this.selectedReason
      },
      set (payload) {
        this.selectedReason = payload
        this.messages = []
      }
    },
    manager () {
      return this.clients[this.$route.params.clientId] && this.clients[this.$route.params.clientId].manager
        ? this.clients[this.$route.params.clientId].manager
        : {}
    },
    rules () {
      return [value => !!value || 'Required.']
    }
  },
  methods: {
    ...mapMutations('recurringPayments', ['setLoading']),
    ...mapActions('recurringPayments', ['cancelRecurringPayment']),
    submitCancellation () {
      if (this.selectedReason === 'Other') {
        if (!this.feedback) {
          this.messages = [this.$i18n.t('payment.fieldRequired')]
          return
        }
      }
      this.cancelRecurringPayment({
        clientId: this.$route.params.clientId,
        paymentId: this.paymentId,
        query: {
          reason: this.selectedReason,
          feedback: this.feedback
        }
      })
      this.closeModal()
      this.setDefaults()
    },
    setDefaults () {
      this.selectedReason = this.defaultReason
      this.feedback = ''
      this.messages = []
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    closeModal () {
      this.dialog = false
    },
    onModalChange () {
      if (this.dialog) {
        this.setDefaults()
        this.submitted = false
      }
    }
  }
}
</script>
